import React from 'react';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import config from '../../config';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Subscribe from '../components/Subscribe';
import Header from '../components/Header';

import ipad from '../assets/images/about.jpg';
import demoImage1 from '../assets/images/jogadores.jpg';
import demoImage2 from '../assets/images/clubes.jpg';
import bgMaster from '../assets/images/estadio.jpg';
import timeline from '../assets/images/timeline.png';

const IndexPage = () => (
  <Layout>
    <Header />

    <header className="masthead">
      <div className="container d-flex h-100 align-items-center">
        <div className="mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">{config.heading}</h1>
          <h2 className="text-white-50 mx-auto mt-2 mb-5">
            {config.subHeading}
          </h2>
          <Scroll type="id" element="about">
            <a href="#about" className="btn btn-primary">
              About
            </a>
          </Scroll>
        </div>
      </div>
    </header>

    <section id="about" className="about-section text-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h2 className="text-white mb-4">{config.siteTitle}</h2>
            <p className="text-white-50">
            MCV’ is an international sports agency specialized in the management of football athletes. Founded in January 2009, and headquartered in Lisbon, we are growing exponentially through our personal approach to each client as we help them achieve and fulfil their athletic & professional potential.
            Passion for sports is at the epicentre of everything we do, fuelling us each and every day to provide quality services and help clients achieve extraordinary results.
              {/* <a href="https://github.com/anubhavsrivastava/gatsby-starter-grayscale">
                the github repo
              </a>
              . */}
            </p>
          </div>
        </div>
        {/* <img src={ipad} className="img-fluid" alt="" /> */}
      </div>
    </section>

    <section id="projects" className="projects-section bg-light">
      <div className="container">
        

        <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
          <div className="col-lg-6">
            <img className="img-fluid" src={demoImage1} alt="" />
          </div>
          <div className="col-lg-6">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-left">
                  <h4 className="text-white">SERVICES FOR ATHLETES</h4>
                  <p className="mb-0 text-white-50">
                  The services we offer at MCV Sports are not only endowed with professionalism, trust and transparency, but are also specific to the individuality of each athlete.
Our work comes naturally from the collaboration in the negotiations since the signing of the first contract, as well as with the rest of the contracting and personal development process, thus valuing the demands of the athlete. We consider it extremely important that our service is developed based on trust and transparency, through our experience and knowledge. This way, we want to develop the performance of personal and social-professional skills, aligned with the achievement of professional success.
Our advice includes services for managing and administering everyday life processes, such as: taxes, insurance plans, house payments, rent payments, sponsorships, retirement plans, among others. It is notorious that these services are completely defined according to the aspirations and requirements of the athlete.
                  </p>
                  <hr className="d-none d-lg-block mb-0 ml-0" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center no-gutters">
          <div className="col-lg-6">
            <img className="img-fluid" src={demoImage2} alt="" />
          </div>
          <div className="col-lg-6 order-lg-first">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-right">
                  <h4 className="text-white">SERVICES FOR SPORTS ORGANIZATIONS</h4>
                  <p className="mb-0 text-white-50">
                  MCV Sports collaborates with clubs in search of the right player.
Currently, MCV Sports cooperates with the international football world, from the division of honor to the Champions League, as well as with national and international federations.
To point out, the strong growth of the company in the last two years, MCV Sports believes that its basic principles of transparency, description, flexibility and trust, are key pillars in the sustainable development.
                  </p>
                  <hr className="d-none d-lg-block mb-0 mr-0" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Subscribe />

    <SocialLinks />
    <Footer />
  </Layout>
);

export default IndexPage;
